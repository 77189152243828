@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Sofia-Pro', sans-serif;
}

@font-face {
    font-family: 'Sofia-Pro';
    src: url('../assets/fonts/Sofia Pro Black Az.woff');
    font-weight: 900;
}

@font-face {
    font-family: 'Sofia-Pro';
    src: url('../assets/fonts/Sofia Pro Bold Az.woff');
    font-weight: 800;
}

@font-face {
    font-family: 'Sofia-Pro';
    src: url('../assets/fonts/Sofia Pro Semi Bold Az.woff');
    font-weight: 700;
}

@font-face {
    font-family: 'Sofia-Pro';
    src: url('../assets/fonts/Sofia Pro Medium Az.woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Sofia-Pro';
    src: url('../assets/fonts/Sofia Pro Regular Az.woff');
    font-weight: 400;
}

@font-face {
    font-family: 'Sofia-Pro';
    src: url('../assets/fonts/Sofia Pro Light Az.woff');
    font-weight: 300;
}

@layer utilities {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
